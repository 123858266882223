import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { PoNotificationService } from '@po-ui/ng-components';
import { Observable, of, throwError, pipe } from 'rxjs';
import { map, catchError, tap, retry } from 'rxjs/operators';

import { Token } from '../../classes/Token';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  //-- VARIAVEIS -----------------------------------------------------------------------------------------------------
  public userToken = new Token();
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-type': 'application/json',
      Authorization: this.userToken.token || 'tokeninvalido',
    }),
  };
  public sUsername: string;
  public sToken: string;
  public sRoles: string;
  public sSquads: string;

  constructor(
    private _http: HttpClient,
    public poNotification: PoNotificationService
  ) {}

  updateHeaders() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: this.userToken.token,
      }),
    };
  }
  //-- SEÇÃO -----------------------------------------------------------------------------------------------------
  public validSession() {
    if (
      sessionStorage.getItem('User') !== null &&
      sessionStorage.getItem('User') !== '' &&
      sessionStorage.getItem('Token') !== null &&
      sessionStorage.getItem('Token') !== ''
    ) {
      this.userToken.id = parseInt(sessionStorage.getItem('id'));
      this.userToken.token = sessionStorage.getItem('Token');
      this.userToken.username = sessionStorage.getItem('User');
      this.userToken.roles = JSON.parse(sessionStorage.getItem('Roles'));
      this.userToken.squads = JSON.parse(sessionStorage.getItem('Squads'));
      this.userToken.approved = JSON.parse(sessionStorage.getItem('approved'));
      this.updateHeaders();
    }
  }

  public logout() {
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('Token');
    sessionStorage.removeItem('User');
    sessionStorage.removeItem('Roles');
    sessionStorage.removeItem('Squads');
    location.reload();
  }

  //-- LOGIN -----------------------------------------------------------------------------------------------------
  getUsers(): Observable<any> {
    return this._http
      .get(environment.url_api + '/users', this.httpOptions)
      .pipe(
        retry(3),
        map((users) => {
          return users;
        })
        //catchError(this.handleError)
      );
  }

  getToken(username, password): Observable<any> {
    return this._http
      .post<any>(
        environment.url_api + '/auth/login',
        JSON.stringify({ username, password }),
        this.httpOptions
      )
      .pipe(
        map((res) => {
          this.userToken.id = res.id;
          this.userToken.token = res.token;
          // this.userToken.roles = res.approved ? res.roles : 'devteam'; //-- Em caso do usuario não estiver aprovado, ficará com acesso de devteam
          this.userToken.roles = res.roles;
          this.userToken.squads = res.squads.map((squad) => squad.id);
          this.userToken.approved = res.approved;
          this.updateHeaders();

          sessionStorage.setItem('id', JSON.stringify(this.userToken.id));
          sessionStorage.setItem('Token', this.userToken.token);
          sessionStorage.setItem('Roles', JSON.stringify(this.userToken.roles));
          sessionStorage.setItem(
            'Squads',
            JSON.stringify(this.userToken.squads)
          );
          sessionStorage.setItem('User', this.userToken.username);
          sessionStorage.setItem(
            'Squads',
            JSON.stringify(this.userToken.squads)
          );
          sessionStorage.setItem('approved', JSON.stringify(this.userToken.approved));
          return this.userToken.token;
        }),
        catchError(this.handleError)
      );
  }

  checkToken(): boolean {
    if (
      this.userToken.token !== null &&
      this.userToken.token !== '' &&
      this.userToken.token !== undefined
    ) {
      return true;
    } else {
      this.validSession();
      if (
        this.userToken.token !== null &&
        this.userToken.token !== '' &&
        this.userToken.token !== undefined
      ) {
        this.updateHeaders();
        return true;
      }
      return false;
    }
  }

  changeDataUser(tokenUser: Token): Observable<any> {
    tokenUser = Object.assign(tokenUser, { approved: false });

    this.updateHeaders();
    return this._http
      .put(
        environment.url_api + '/users/' + tokenUser.id + '/myacess',
        JSON.stringify(tokenUser),
        this.httpOptions
      )
      .pipe(
        map((res) => {
          this.userToken.squads = tokenUser.squads;
          sessionStorage.setItem(
            'Squads',
            JSON.stringify(this.userToken.squads)
          );
          this.userToken.roles = tokenUser.roles;
          sessionStorage.setItem('Roles', JSON.stringify(this.userToken.roles));
          return res;
        }),
        catchError(this.handleError)
      );
  }

  //-- ACTION ------------------------------------------------------------------------------------------

  handleError(error: HttpErrorResponse) {
    if (error instanceof ErrorEvent) {
      console.error('Erro ocorrido: ', error);
    } else {
      console.log(error);

      console.error(
        'O servidor te ignorou e respondeu ' +
          error.status +
          ' e ainda disse: ' +
          error.message
      );
    }
    return throwError('I have a bad feeling about this...');
  }

  handleMessage(text: string, type?: Number) {
    switch (type) {
      case 1:
        this.poNotification.success(text);
        break;
      case 2:
        this.poNotification.information(text);
        break;
      case 3:
        this.poNotification.warning(text);
        break;
      default:
        this.poNotification.error(text);
        break;
    }
  }
}
