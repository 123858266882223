import { Injectable } from '@angular/core';

import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Route,
} from '@angular/router';
import { Observable } from 'rxjs';

import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root',
})
export class ApprovedGuardChild  {
  constructor(private authService: LoginService, private router: Router) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (this.authService.checkToken()) {
      if (this.authService.userToken.approved) {
        return true;
      }
      else {
        this.router.navigate(['home']);
      }
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }

  canLoad(rota: Route): Observable<boolean> | boolean {
    if (this.authService.checkToken()) {
      if (this.authService.userToken.approved) {
        return true;
      }
      else {
        this.router.navigate(['home']);
      }
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }
}
