import { Component, OnInit, ViewChild } from '@angular/core';
import {
  PoMenuItem,
  PoModalAction,
  PoModalComponent,
  PoMultiselectOption,
  PoSelectOption,
  PoToolbarAction,
} from '@po-ui/ng-components';
import { Token } from 'src/classes/Token';
import { LoginService } from '../login/login.service';
import { SquadsService } from '../squads/squads.service';
import { UsersService } from '../users/users.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @ViewChild('registerUsers', { static: true }) selectUsers: PoModalComponent;

  public name = '';
  public selectedRoles: Array<string> = [];
  public selectedCareer: Array<string> = [];
  public selectedSquads: Array<number> = [];

  public roles: Array<PoSelectOption> = [];
  public career: Array<PoSelectOption> = [];
  public squads: Array<PoMultiselectOption> = [];

  readonly menus: Array<PoMenuItem> = [
    {
      label: 'Home',
      icon: 'po-icon po-icon-home',
      shortLabel: 'Home',
      link: '/home',
    },
  ];

  profileActions: Array<PoToolbarAction> = [
    {
      icon: 'po-icon-exit',
      label: 'Sair',
      type: 'danger',
      separator: true,
      action: (user) => this.loginService.logout(),
    },
  ];

  close: PoModalAction = {
    action: () => {
      this.selectUsers.close();
    },
    label: 'Cancelar',
    danger: true,
  };

  save: PoModalAction = {
    action: () => {
      this.saveUserData();
    },
    label: 'Salvar',
  };

  constructor(
    public loginService: LoginService,
    public squadService: SquadsService,
    public usersService: UsersService
  ) {}

  ngOnInit(): void {
    this.getSquads();
    this.roles = this.usersService.getRoles([], true);
    this.career = this.usersService.getCareer('');
    this.selectedRoles = this.loginService.userToken.roles;

    if (this.loginService.userToken.token === '') {
      this.loginService.validSession();
    }
    if (
      !this.loginService.userToken.squads[0] ||
      !this.loginService.userToken.roles[0]
    ) {
      this.selectUsers.open();
    }

    this.setupMenus()
    
  }

  setupMenus() {
    const userToken = this.loginService.userToken;
    const userRoles = this.loginService.userToken.roles;
    const isAdmin = userRoles.includes('admin');
    const isCoach = userRoles.includes('agilecoach');
    const isLead = userRoles.includes('teamlead');
    const isTribe = userRoles.includes('tribelead');
    const isEpic = userRoles.includes('epicowner');

    if (userToken.approved) {
      if (isAdmin) {
        this.addAdminMenus();
      }
      if (!isAdmin && !isCoach && isLead) {
        this.addTeamLeadMenus();
      }
      if (!isAdmin && !isLead && !isCoach && isTribe) {
        this.addTribeLeadMenus();
      }
      if (!isAdmin && isCoach) {
        this.addAgileCoachMenus();
      }
      if (!userToken.roles.some(role => ['admin', 'teamlead', 'tribelead', 'agilecoach'].includes(role))) {
        this.addDefaultMenus();
      }
    }
  }

  addAdminMenus() {
    this.menus.push({
      label: 'Agile Game',
      icon: 'po-icon po-icon-document-filled',
      shortLabel: 'Agile Game',
      link: '/agile-game',
      subItems: [
        { label: 'Equipe', link: '/agile-game/team' },
        { label: 'Meu Planejamento', link: '/agile-game/team-planning' },
        { label: 'Orçado X Realizado', link: '/agile-game/achieved-goals' },
        { label: 'MVT', link: '/agile-game/mvt' },
        {
          label: 'Apuração',
          icon: 'po-icon',
          link: '/counting',
          shortLabel: 'Apuração',
          subItems: [
            { label: 'Apuração Coletiva', link: '/counting/collective' },
            { label: 'Apuração Individual', link: '/counting/individual' },
          ],
        },
        {
          label: 'Histórico',
          icon: 'po-icon po-icon-history',
          shortLabel: 'Histórico',
          link: '/history',
          subItems: [{ label: 'Minhas Metas', link: '/history/my-goals' }],
        },
        { label: 'Relatório de Acessos', link: '/agile-game/user-log'},
      ],
    });
    this.menus.push({
      label: 'Matriz de Competências',
      icon: 'po-icon po-icon-light',
      shortLabel: 'Matriz',
      link: '/matrix-competency',
      subItems: [
        { label: 'Grupo de Competências', link: '/matrix-competency/group-competences'},
        { label: 'Competências', link: '/matrix-competency/competences'},
        { label: 'Configuração da Matriz', link: '/matrix-competency/config-matriz' },
        { label: 'Matriz de Competências', link: '/matrix-competency/matrix'},
      ],
    });
    this.menus.push({
      label: 'Dashboard',
      icon: 'po-icon po-icon-chart-columns',
      shortLabel: 'Dashboard',
      link: '/dashboard-matrix',
      subItems: [
        { label: 'Vulnerabilidade', link: '/dashboard-matrix/operational-vulnerability-dashboard'},
        { label: 'Efetividade', link: '/dashboard-matrix/plan-effectiveness-dashboard'}
      ],
    });
    this.menus.push({
      label: 'Opções Administrativas',
      icon: 'po-icon po-icon-settings',
      shortLabel: 'Opções',
      subItems: [
        { label: 'Aprovação de Usuários', link: '/adm/approve' },
        { label: 'Ciclos', link: '/adm/cicles' },
        { label: 'Competências', link: '/adm/competence' },
        { label: 'Tipo de Competências', link: '/adm/competence-type' },
        { label: 'Equipes', link: '/adm/squads' },
        { label: 'Metas', link: '/adm/goals' },
        { label: 'Peso das Metas', link: '/adm/goal-weight' },
        { label: 'Tribes', link: '/adm/tribes' },
        { label: 'Usuários', link: '/adm/users' },
        { label: 'Worker', link: '/adm/worker'}          
      ],
    });
  }

  addTeamLeadMenus() {
    this.menus.push({
      label: 'Agile Game',
      icon: 'po-icon po-icon-document-filled',
      shortLabel: 'Agile Game',
      link: '/agile-game',
      subItems: [
        { label: 'Equipe', link: '/agile-game/team' },
        { label: 'Meu Planejamento', link: '/agile-game/team-planning' },
        { label: 'Orçado X Realizado', link: '/agile-game/achieved-goals' },
        { label: 'MVT', link: '/agile-game/mvt' },
        {
          label: 'Apuração',
          icon: 'po-icon',
          link: '/counting',
          shortLabel: 'Apuração',
          subItems: [
            { label: 'Apuração Coletiva', link: '/counting/collective' },
            { label: 'Apuração Indivídual', link: '/counting/individual' },
          ],
        },
        {
          label: 'Histórico',
          icon: 'po-icon po-icon-history',
          shortLabel: 'Histórico',
          link: '/history',
          subItems: [{ label: 'Minhas Metas', link: '/history/my-goals' }],
        },
        { label: 'Relatório de Acessos', link: '/agile-game/user-log'},
        
      ],
    });
    this.menus.push({
      label: 'Matriz de Competências',
      icon: 'po-icon po-icon-light',
      shortLabel: 'Matriz',
      link: '/matrix-competency',
      subItems: [
        { label: 'Configuração da Matriz', link: '/matrix-competency/config-matriz' },
        { label: 'Matriz de Competências', link: '/matrix-competency/matrix'},
      ],
    });
    this.menus.push({
      label: 'Dashboard',
      icon: 'po-icon po-icon-chart-columns',
      shortLabel: 'Dashboard',
      link: '/dashboard-vulnerabillity',
      subItems: [
        { label: 'Vulnerabilidade', link: '/dashboard-matrix/operational-vulnerability-dashboard'},
        { label: 'Efetividade', link: '/dashboard-matrix/plan-effectiveness-dashboard'}
      ],
    });
  }

  addTribeLeadMenus() {
    this.menus.push({
      label: 'Agile Game',
      icon: 'po-icon po-icon-document-filled',
      shortLabel: 'Agile Game',
      link: '/agile-game',
      subItems: [
        {
          label: 'Orçado X Realizado',
          link: '/agile-game/achieved-goals',
        },
        {
          label: 'Apuração',
          icon: 'po-icon',
          link: '/counting',
          shortLabel: 'Apuração',
          subItems: [
            { label: 'Apuração Coletiva', link: '/counting/collective' },
          ],
        },
        {
          label: 'Histórico',
          icon: 'po-icon po-icon-history',
          shortLabel: 'Histórico',
          link: '/history',
          subItems: [
            { label: 'Minhas Metas', link: '/history/my-goals' },
          ],
        },
        { label: 'Relatório de Acessos', link: '/agile-game/user-log'},
      ],
    });
    this.menus.push({
      label: 'Matriz de Competências',
      icon: 'po-icon po-icon-light',
      shortLabel: 'Matriz',
      link: '/matrix-competency',
      subItems: [
        { label: 'Configuração da Matriz', link: '/matrix-competency/config-matriz' },
        { label: 'Matriz de Competências', link: '/matrix-competency/matrix'},
      ],
    });
    this.menus.push({
      label: 'Dashboard',
      icon: 'po-icon po-icon-chart-columns',
      shortLabel: 'Dashboard',
      link: '/dashboard-vulnerabillity',
      subItems: [
        { label: 'Vulnerabilidade', link: '/dashboard-matrix/operational-vulnerability-dashboard'},
        { label: 'Efetividade', link: '/dashboard-matrix/plan-effectiveness-dashboard'}
      ],
    });
  }

  addAgileCoachMenus() {
    this.menus.push({
      label: 'Agile Game',
      icon: 'po-icon po-icon-document-filled',
      shortLabel: 'Agile Game',
      link: '/agile-game',
      subItems: [
          { label: 'Equipe', link: '/agile-game/team' },
          { label: 'Meu Planejamento', link: '/agile-game/team-planning' },
          {label: 'Orçado X Realizado',link: '/agile-game/achieved-goals'},
        { label: 'MVT', link: '/agile-game/mvt' },
        {
          label: 'Apuração',
          icon: 'po-icon po-icon-history',
          shortLabel: 'Apuração',
          subItems: [
            { label: 'Apuração Coletiva', link: '/counting/collective' },
            { label: 'Apuração Indivídual', link: '/counting/individual' }
          ],
        },
        {
          label: 'Histórico',
          icon: 'po-icon po-icon-history',
          shortLabel: 'Histórico',
          link: '/history',
          subItems: [
            { label: 'Minhas Metas', link: '/history/my-goals' },
          ],
        },
        { label: 'Relatório de Acessos', link: '/agile-game/user-log'},
      ],
    });
    this.menus.push({
      label: 'Matriz de Competências',
      icon: 'po-icon po-icon-light',
      shortLabel: 'Matriz',
      link: '/matrix-competency',
      subItems: [
        { label: 'Configuração da Matriz', link: '/matrix-competency/config-matriz' },
        { label: 'Matriz de Competências', link: '/matrix-competency/matrix'},
      ],
    });
    this.menus.push({
      label: 'Dashboard',
      icon: 'po-icon po-icon-chart-columns',
      shortLabel: 'Dashboard',
      link: '/dashboard-vulnerabillity',
      subItems: [
        { label: 'Vulnerabilidade', link: '/dashboard-matrix/operational-vulnerability-dashboard'},
        { label: 'Efetividade', link: '/dashboard-matrix/plan-effectiveness-dashboard'}
      ],
    });
    this.menus.push({
      label: 'Opções Administrativas',
      icon: 'po-icon po-icon-settings',
      shortLabel: 'Opções',
      subItems: [
        { label: 'Ciclos', link: '/adm/cicles' },
        { label: 'Competências', link: '/adm/competence' },
        { label: 'Tipo de Competências', link: '/adm/competence-type' },
        { label: 'Equipes', link: '/adm/squads' },
        { label: 'Peso das Metas', link: '/adm/goal-weight' },
        { label: 'Tribes', link: '/adm/tribes' },
      ],
    });
  }
  
  addDefaultMenus() {
    this.menus.push({
      label: 'Agile Game',
      icon: 'po-icon po-icon-document-filled',
      shortLabel: 'Agile Game',
      link: '/agile-game',
      subItems: [
        {
          label: 'Orçado X Realizado',
          link: '/agile-game/achieved-goals',
        },
        {
          label: 'Apuração',
          icon: 'po-icon',
          link: '/counting',
          shortLabel: 'Apuração',
          subItems: [
            { label: 'Apuração Coletiva', link: '/counting/collective' },
          ],
        },
        {
          label: 'Histórico',
          icon: 'po-icon po-icon-history',
          shortLabel: 'Histórico',
          link: '/history',
          subItems: [
            { label: 'Minhas Metas', link: '/history/my-goals' },
          ],
        },
        { label: 'Relatório de Acessos', link: '/agile-game/user-log'},
      ],
    });
    this.menus.push({
      label: 'Matriz de Competências',
      icon: 'po-icon po-icon-light',
      shortLabel: 'Matriz',
      link: '/matrix-competency',
      subItems: [
        { label: 'Configuração da Matriz', link: '/matrix-competency/config-matriz' },
        { label: 'Matriz de Competências', link: '/matrix-competency/matrix'},
      ],
    });
    this.menus.push({
      label: 'Dashboard',
      icon: 'po-icon po-icon-chart-columns',
      shortLabel: 'Dashboard',
      link: '/dashboard-vulnerabillity',
      subItems: [
        { label: 'Vulnerabilidade', link: '/dashboard-matrix/operational-vulnerability-dashboard'},
        { label: 'Efetividade', link: '/dashboard-matrix/plan-effectiveness-dashboard'}
      ],
    });
  }

  getSquads() {
    this.squadService.get().subscribe((squads) => {
      squads.forEach((squad) => {
        this.squads = [
          ...this.squads,
          {
            label: `${squad.tribeSegment}-${squad.tribeName}-${squad.name}`,
            value: squad.id,
          },
        ];
      });
      this.selectedSquads = this.loginService.userToken.squads;
    });
  }

  saveUserData() {
    const tokenUser: Token = Object.assign(
      {},
      {
        id: this.loginService.userToken.id,
        username: this.loginService.userToken.username,
        name: this.name,
        squads: this.selectedSquads,
        roles: this.selectedRoles,
        career: this.selectedCareer,
        token: this.loginService.userToken.token,
        approved: this.loginService.userToken.approved
      }
    );

    
    if (this.name != '' && this.selectedSquads.length != 0  && this.selectedCareer.length != 0) {
      this.loginService.changeDataUser(tokenUser).subscribe((res) => {
        this.loginService.handleMessage(
          'Usuário salvo com sucesso, aguarde a aprovação do Administrador para ter acesso aos recursos.',
          1
        );
        this.selectUsers.close();
      });
    }
    else {
      this.loginService.handleMessage('Não foi possível salvar o usuário. Preencha todos os campos para prosseguir.');
    }

  }
}
