import { Injectable } from '@angular/core';
import { PoTableColumn } from '@po-ui/ng-components';
import { LoginService } from '../login/login.service';
import { environment } from 'src/environments/environment';
import { retry, map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { Cicle } from 'src/classes/Cicle';

@Injectable({
  providedIn: 'root',
})
export class CiclesService {
  constructor(private _http: HttpClient, public loginService: LoginService) {}

  getColumns(): Array<PoTableColumn> {
    return [
      { property: 'id', label: 'ID Ciclo', type: 'number' },
      { property: 'name', label: 'Nome Ciclo' },
      { property: 'frequency', label: 'Frequência' },
      { property: 'number', label: 'Número do ciclo ' },
      { property: 'year', label: 'Ano' },
      {
        property: 'agilegame',
        label: 'Etapas de Ciclo',
        type: 'boolean',
        width: '8%',
        boolean: {
          trueLabel: 'Habilitado',
          falseLabel: 'Desabilitado'
        }
      }
    ];
  }

  public id: number;
  public name: string;
  public frequency: string;

  /**
   * Array das informações que será apresentado no po-select referente a frequencia
   *
   * @return {Array}  {Array<any>}
   * @memberof CiclesService
   */
  getFrequencies(): Array<any> {
    return [
      { value: 'anual', label: 'Anual', numbers: 1, monthly: 12 },
      { value: 'bimestral', label: 'Bimestral', numbers: 6, monthly: 2 },
      { value: 'semestral', label: 'Semestral', numbers: 2, monthly: 6 },
      { value: 'mensal', label: 'Mensal', numbers: 12, monthly: 1 },
      {
        value: 'quadrimestral',
        label: 'Quadrimestral',
        numbers: 3,
        monthly: 4,
      },
      { value: 'trimestral', label: 'Trimestral', numbers: 4, monthly: 3 },
    ];
  }

  /**
   * GET para retornar os ciclos já cadastrados.
   *
   * @return {*}  Array dos ciclos cadastrados.
   * @memberof CiclesService
   */
  get(): Observable<any> {
    return this._http
      .get(environment.url_api + '/cicles', this.loginService.httpOptions)
      .pipe(
        retry(3),
        map((res) => {
          return res;
        }),
        catchError(this.loginService.handleError)
      );
  }

  getAgileGameActive(): Observable<any> {
    return this._http
      .get(environment.url_api + '/cicles', this.loginService.httpOptions)
      .pipe(
        retry(3),
        map((res: any[]) => {
          return res.filter((element) => element.agilegame == true);
        }),
        catchError(this.loginService.handleError)
      );
  }

  /**
   * GET para retornar somente um ciclo de acordo com o ID informado
   *
   * @param {string|number} id
   * @return {*}  Objeto contendo informações referente a um unico ciclo.
   * @memberof CiclesService
   */
  getOne(id: string | number): Observable<any> {
    return this._http
      .get(environment.url_api + '/cicles/' + id, this.loginService.httpOptions)
      .pipe(
        retry(3),
        map((res) => {
          return res;
        }),
        catchError(this.loginService.handleError)
      );
  }

    public async activeToRegister(id: number) {
      if (this.loginService.userToken.roles.includes('admin') || this.loginService.userToken.roles.includes('agilecoach')) {
        return false;
      } else {
        let cicle = await lastValueFrom(this.getOne(id));
        let end  = new Date (cicle.registerEnd).toISOString();
        let start = new Date (cicle.registerStart).toISOString();
        let today = new Date().toISOString();
        let isNotActive = this.compareDate(start, end, today);

        return isNotActive;
      }
    }

    
    compareDate(start, end, today) {
      let yearStart = start.substring(0, 4);
      let monthStart = start.substring(5, 7);
      let dayStart = start.substring(8, 10);
  
      let yearToday = today.substring(0, 4);
      let monthToday = today.substring(5, 7);
      let dayToday = today.substring(8, 10);
  
      let yearEnd = end.substring(0, 4);
      let monthEnd = end.substring(5, 7);
      let dayEnd = end.substring(8, 10);
  
      let startDate = new Date(yearStart, monthStart - 1, dayStart);
      let todayDate = new Date(yearToday, monthToday - 1, dayToday);
      let endDate = new Date(yearEnd, monthEnd - 1, dayEnd);

      if (startDate.getTime() > todayDate.getTime() || endDate.getTime() < todayDate.getTime()) {
        return true;
      } else if (startDate.getTime() <= todayDate.getTime() && endDate.getTime() >= todayDate.getTime()) {
        return false;
      }
    }

  /**
   * GET para retornar somente um ciclo de acordo com o ano informado
   *
   * @param {string|number} id
   * @return {*}  Objeto contendo informações referente a ciclos do ano informado.
   * @memberof CiclesService
   */
   getCicleByYear(year: string | number): Observable<any> {
    return this._http
      .get(environment.url_api + '/cicles/year/' + year, this.loginService.httpOptions)
      .pipe(
        retry(3),
        map((res) => {
          return res;
        }),
        catchError(this.loginService.handleError)
      );
  }

  /**
   * GET para consultar os ciclos cadastrados de acordo com filtro expecifico de string
   * Chamadas em: [GoalWeightListComponent(filterCicles())] | [CiclesListComponent(onQuickSearch())]
   * @param {string} filter
   * @return {*}  {Observable<any>}
   * @memberof CiclesService
   */
  getFiltered(filter: string): Observable<any> {
    return this._http
      .get(
        environment.url_api + '/cicles?' + filter,
        this.loginService.httpOptions
      )
      .pipe(
        retry(3),
        map((res) => {
          return res;
        }),
        catchError(this.loginService.handleError)
      );
  }

  save(params): Observable<any> {
    return this._http
      .post(
        environment.url_api + '/cicles',
        JSON.stringify(params),
        this.loginService.httpOptions
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.loginService.handleError)
      );
  }

  edit(params): Observable<any> {
    return this._http
      .put(
        environment.url_api + '/cicles/' + params.id,
        JSON.stringify(params),
        this.loginService.httpOptions
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.loginService.handleError)
      );
  }

  delete(id): Observable<any> {
    return this._http
      .delete(
        environment.url_api + '/cicles/' + id,
        this.loginService.httpOptions
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.loginService.handleError)
      );
  }

  /**
   * Função que irá retornar todos os ciclos cadastrado para todas as tribes do segmento
   *
   * @param {string} filter
   * @memberof CiclesService
   */
  getCicleSegment(filter: string): Observable<any> {
    return this._http
      .get(
        environment.url_api + '/cicles/?' + filter,
        this.loginService.httpOptions
      )
      .pipe(
        map((res: any[]) => {
          return res.filter((element) => element.active == true);;
        }),
        catchError(this.loginService.handleError)
      );
  }

  async getCicles(filter: string): Promise<any> {
    return await lastValueFrom(this._http
      .get(
        environment.url_api + '/cicles?' + filter,
        this.loginService.httpOptions
      )
      .pipe(
        retry(3),
        map((res) => {
          return res;
        }),
        catchError(this.loginService.handleError)
      ));
  }
}
