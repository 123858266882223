<div
  class="po-font-display"
  style="position: fixed; top: 72.22%; left: 51%; color: #ffffff"
>
  <span>TOTVS</span><br />
  <span>Painel da Gestão</span>
</div>
<po-page-login
  p-hide-remember-user
  [p-loading]="loading"
  (p-login-submit)="checkLogin($event)"
  p-blocked-url="/documentation/po-page-blocked-user"
  p-logo="assets/logos/totvs-backoffice.svg"
  p-secondary-logo="assets/logos/totvs.svg"
  p-background="assets/images/background.jpg"
  [p-literals]="customLiterals"
  p-product-name="Painel da Gestão"
>
</po-page-login>

<div class="sample-container" *ngIf="loading">
  <po-loading-overlay></po-loading-overlay>
</div>
