import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  PoMultiselectOption,
  PoSelectOption,
  PoTableColumn,
} from '@po-ui/ng-components';
import { Observable } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { Squad } from 'src/classes/Squad';
import { User } from 'src/classes/User';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private _http: HttpClient, public loginservice: LoginService) {}

  getRoles(
    selectedRoles: Array<any> = [],
    withoutAdmin = false
  ): Array<PoMultiselectOption> {
    const roles: Array<PoMultiselectOption> = [];

    if (!withoutAdmin) {
      roles.push({ value: 'admin', label: 'Admin' });
    }
    roles.push(
      { value: 'agilecoach', label: 'Agile Coach' },
      { value: 'agilemaster', label: 'Agile Master' },
      { value: 'devteam', label: 'Dev Team' },
      { value: 'diretor', label: 'Diretor' },
      { value: 'epicowner', label: 'Epic Owner' },
      { value: 'peoplelead', label: 'People Lead' },
      { value: 'productmanager', label: 'Product Manager' },
      { value: 'productowner', label: 'Product Owner' },
      { value: 'teamlead', label: 'Team Lead' },
      { value: 'tribelead', label: 'Tribe Lead' }
    );

    const fielteredRolesSelected: Array<PoMultiselectOption> = roles.filter(
      (role) => selectedRoles.includes(role.value)
    );

    const withoutFielteredRolesSelected: Array<PoMultiselectOption> =
      roles.filter(
        (role: PoMultiselectOption) => !selectedRoles.includes(role.value)
      );

    return [...fielteredRolesSelected, ...withoutFielteredRolesSelected];
  }

  getCareer(selectedCareer: string): Array<PoSelectOption> {
    const career: Array<PoSelectOption> = [];
    career.push(
      { value: 'Jovem Aprendiz', label: 'Jovem Aprendiz' },
      { value: 'Estagiário', label: 'Estagiário' },
      { value: 'Assistente/Técnico', label: 'Assistente/Técnico' },
      { value: 'Analista I', label: 'Analista I' },
      { value: 'Analista II', label: 'Analista II' },
      { value: 'Analista III', label: 'Analista III' },
      { value: 'Especialista I', label: 'Especialista I' },
      { value: 'Especialista II', label: 'Especialista II' },
      { value: 'Especialista III', label: 'Especialista III' },
      { value: 'Master', label: 'Master' },
      { value: 'Expert', label: 'Expert' },
      { value: 'Diretor Tech', label: 'Diretor Tech' },
      { value: 'Líder', label: 'Líder' },
      { value: 'Coordenador', label: 'Coordenador' },
      { value: 'Gerente', label: 'Gerente' },
      { value: 'Gerente Executivo', label: 'Gerente Executivo' },
      { value: 'Diretor', label: 'Diretor' }
    );

    const fielteredCareerSelected: Array<PoSelectOption> = career.filter(
      (career) => selectedCareer.includes(<string>career.value)
    );

    const withoutFielteredCarreerSelected: Array<PoSelectOption> =
      career.filter(
        (career: PoSelectOption) =>
          !selectedCareer.includes(<string>career.value)
      );

    return [...fielteredCareerSelected, ...withoutFielteredCarreerSelected];
  }

  getSquadRoles(): Array<PoMultiselectOption> {
    return [
      { value: 'analistatecnico', label: 'Analista Técnico' },
      { value: 'analistafuncional', label: 'Analista Funcional' },
    ];
  }

  getColumns(): Array<PoTableColumn> {
    return [
      { property: 'id', label: 'ID', type: 'number' },
      { property: 'username', label: 'Usuário', type: 'string' },
      { property: 'roles', label: 'Papel' },
      { property: 'career', label: 'Nível de Carreira' },
      {
        label: 'Aprovação',
        property: 'approved',
        type: 'label',
        width: '150px',
        labels: [
          { value: 1, color: 'color-11', label: 'Aprovado' },
          { value: 0, color: 'color-08', label: 'Pendente' },
        ],
      },
      {
        label: 'Status',
        property: 'active',
        type: 'boolean',
        width: '8%',
        boolean: {
          trueLabel: 'Ativo',
          falseLabel: 'Inativo',
        }
      }
    ];
  }

  getUsers(): Observable<Array<User>> {
    return this._http
      .get<Array<User>>(
        environment.url_api + '/users',
        this.loginservice.httpOptions
      )
      .pipe(
        retry(3),
        map((users: Array<User>) => {
          return users.map((user: any) => {
            const {
              id,
              username,
              name,
              squadRole,
              approved,
              roles,
              squads,
              career,
              email,
              picture,
              active
            } = user;
            return {
              id,
              username,
              name,
              squadRole,
              approved: approved ? 1 : 0,
              roles,
              squads: squads.map((squad: Squad) => squad.id),
              career,
              email,
              picture,
              active
            };
          });
        }),
        catchError(this.loginservice.handleError)
      );
  }

  getOne(id): Observable<any> {
    return this._http
      .get(environment.url_api + '/users/' + id, this.loginservice.httpOptions)
      .pipe(
        retry(3),
        map((res) => {
          return res;
        }),
        catchError(this.loginservice.handleError)
      );
  }

  getUserTeam(id: number): Observable<Array<any>> {
    return this._http
      .get<Array<User>>(
        environment.url_api + '/users/' + id + '/squads',
        this.loginservice.httpOptions
      )
      .pipe(
        retry(3),
        map((res) => {
          return res;
        }),
        catchError(this.loginservice.handleError)
      );
  }

  getUserTribe(id: number): Observable<Array<any>> {
    return this._http
      .get<Array<User>>(
        environment.url_api + '/users/' + id + '/tribes',
        this.loginservice.httpOptions
      )
      .pipe(
        retry(3),
        map((res) => {
          return res;
        }),
        catchError(this.loginservice.handleError)
      );
  }


  getUsersFiltered(filter): Observable<Array<any>> {
    return this._http
      .get<Array<User>>(
        environment.url_api + '/users?' + filter,
        this.loginservice.httpOptions
      )
      .pipe(
        retry(3),
        map((users) => {
          return users.map((user: any) => {
            const {
              id,
              username,
              name,
              squadRole,
              approved,
              roles,
              squads,
              career,
              email,
              picture,
              active
            } = user;
            return {
              id,
              username,
              name,
              squadRole,
              approved: approved ? 1 : 0,
              roles,
              squads: squads.map((squad: Squad) => squad.id),
              career,
              email,
              picture,
              active
            };
          });
        }),
        catchError(this.loginservice.handleError)
      );
  }

  saveUser(params): Observable<any> {
    return this._http
      .put(
        environment.url_api + '/users/' + params.id,
        JSON.stringify(params),
        this.loginservice.httpOptions
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.loginservice.handleError)
      );
  }

  addSquad(params) {
    return this._http
      .post(
        environment.url_api +
          '/users/' +
          params.id +
          '/squads/' +
          params.idSquad,
        JSON.stringify(params),
        this.loginservice.httpOptions
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.loginservice.handleError)
      );
  }

  removeSquad(params) {
    return this._http
      .delete(
        environment.url_api +
          '/users/' +
          params.id +
          '/squads/' +
          params.idSquad,
        this.loginservice.httpOptions
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.loginservice.handleError)
      );
  }

  deleteUser(id): Observable<any> {
    return this._http
      .delete(
        environment.url_api + '/users/' + id,
        this.loginservice.httpOptions
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.loginservice.handleError)
      );
  }

  getLevel(): Observable<any> {
    return this._http
      .get(environment.url_api + '/career', this.loginservice.httpOptions)
      .pipe(
        retry(3),
        map((res) => {
          return res;
        }),
        catchError(this.loginservice.handleError)
      );
  }
}
