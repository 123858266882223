import { Injectable } from '@angular/core';
import { PoTableColumn } from '@po-ui/ng-components';
import { LoginService } from '../login/login.service';
import { environment } from 'src/environments/environment';
import { retry, map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Squad } from 'src/classes/Squad';

@Injectable({
  providedIn: 'root',
})
export class SquadsService {
  constructor(private _http: HttpClient, public loginservice: LoginService) {}

  public squadEdtion;

  getColumns(): Array<PoTableColumn> {
    return [
      { property: 'id', label: 'ID Equipe', type: 'number' },
      { property: 'name', label: 'Nome Equipe' },
      { property: 'tribeSegment', label: 'Segmento' },
      { property: 'tribeName', label: 'Tribe' },
      { property: 'products', label: 'Produtos' },
    ];
  }

  public get(selectedSquads: Array<any> = []): Observable<any> {
    return this._http
      .get(environment.url_api + '/squads', this.loginservice.httpOptions)
      .pipe(
        retry(3),
        map((squads: Array<any>) => {
          const fielteredSquadsSelected = squads.filter((squad: Squad) => {
            return selectedSquads.includes(squad.id);
          });

          const withoutFielteredSquadsSelected = squads.filter(
            (squad: Squad) => !selectedSquads.includes(squad.id)
          );
          return [
            ...fielteredSquadsSelected,
            ...withoutFielteredSquadsSelected,
          ];
        }),
        catchError(this.loginservice.handleError)
      );
  }

  getOne(id): Observable<any> {
    return this._http
      .get(environment.url_api + '/squads/' + id, this.loginservice.httpOptions)
      .pipe(
        retry(3),
        map((res) => {
          return res;
        }),
        catchError(this.loginservice.handleError)
      );
  }

  getFiltered(filter): Observable<any> {
    return this._http
      .get(
        environment.url_api + '/squads?' + filter,
        this.loginservice.httpOptions
      )
      .pipe(
        retry(3),
        map((squads) => {
          return squads;
        }),
        catchError(this.loginservice.handleError)
      );
  }

  getUserOfSquad(id): Observable<any> {
    return this._http
      .get(
        environment.url_api + '/squads/' + id + '/users',
        this.loginservice.httpOptions
      )
      .pipe(
        retry(3),
        map((users) => {
          return users;
        }),
        catchError(this.loginservice.handleError)
      );
  }

  save(params: Squad): Observable<any> {
    return this._http
      .post(
        environment.url_api + '/squads',
        JSON.stringify(params),
        this.loginservice.httpOptions
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.loginservice.handleError)
      );
  }

  edit(params: Squad): Observable<any> {
    return this._http
      .put(
        environment.url_api + '/squads/' + params.id,
        JSON.stringify(params),
        this.loginservice.httpOptions
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.loginservice.handleError)
      );
  }

  delete(id): Observable<any> {
    return this._http
      .delete(
        environment.url_api + '/squads/' + id,
        this.loginservice.httpOptions
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.loginservice.handleError)
      );
  }
}
