import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { PoPageLoginModule } from '@po-ui/ng-templates';
import { PoModule } from '@po-ui/ng-components';

@NgModule({
  declarations: [LoginComponent],
  imports: [CommonModule, PoModule, PoPageLoginModule],
  exports: [LoginComponent],
})
export class LoginModule {}
