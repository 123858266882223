import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PoDialogService } from '@po-ui/ng-components';

import { LoginService } from './login.service';
import { PoPageLoginLiterals, PoPageLogin } from '@po-ui/ng-templates';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loading: boolean = false;
  public userLogin: string;
  public userPass: string;

  public customLiterals: PoPageLoginLiterals = {
    welcome: 'Gestão de Indicadores',
    loginPlaceholder: 'Insira seu usuário de rede',
  };

  constructor(
    private poDialog: PoDialogService,
    private router: Router,
    public loginservice: LoginService
  ) {}

  ngOnInit(): void {}

  checkLogin(formData: PoPageLogin) {
    this.loading = true;

    this.loginservice.userToken.username = formData.login;
    this.loginservice.userToken.token = '';
    this.loginservice.getToken(formData.login, formData.password).subscribe(
      (token) => {
        this.loginservice.userToken.token = token;
        if (this.loginservice.userToken.squads[0] || this.loginservice.userToken.roles[0]) {
          if (this.loginservice.userToken.approved) {
            this.loading = false;
            this.router.navigate(['home']);
          } else {
            this.loading = false;
            this.loginservice.handleMessage(
              'Usuário ainda não foi aprovado. Entre em contato com um administrador!', 3
            );
          }

        } else {
          if (!this.loginservice.userToken.squads[0] || !this.loginservice.userToken.roles[0]) {
            this.loading = false;
            this.router.navigate(['home']);
          }
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }
}
