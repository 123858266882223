<po-page-default>
  <h1 class="po-font-title">Olá, {{ nameUsr }}</h1>
  <br />
  <br />
  <p *ngIf="ciclesdisabled" class="po-font-subtitle">Desejamos boas vindas ao Painel da Gestão!</p>
  <div *ngIf="!ciclesdisabled && showButton">
    <div *ngFor="let step of steppers" class="stepper-step-container">
      <br>
      <br>
      <h3>Ciclo - {{step.name}}</h3>
      <br>
      <div class="stepper-wrapper" >
        <div class="stepper-item {{step.step1}}">
          <div class="step-counter">
            <span class="po-icon po-icon-ok" *ngIf="step.step1 == 'completed'"></span>
          </div>
          <div class="stepper-step-bar-right"></div>
          <div class="step-name {{step.step1}}">Cadastros de Metas</div>
            <span class="step-date {{step.step1}}">{{step.goalResult}}</span>
        </div>
        <div class="stepper-item {{step.step2}}">
          <div class="step-counter">
            <span class="po-icon po-icon-ok" *ngIf="step.step2 == 'completed'"></span>
          </div>
          <div class="stepper-step-bar-left"></div>
          <div class="stepper-step-bar-right"></div>
          <div class="step-name {{step.step2}} ">Atualização de Metas</div>
          <span class="step-date {{step.step2}}">de 01 a 05 de cada mês</span>
        </div>
        <div class="stepper-item {{step.step3}}">
          <div class="step-counter">
            <span class="po-icon po-icon-ok" *ngIf="step.step3 == 'completed'"></span>
          </div>
          <div class="step-name {{step.step3}}">Resultados Apuração Coletiva/Individual</div>
          <span class="step-date {{step.step3}}">{{step.result}}</span>
        </div>
      </div>
    </div>
  </div>
</po-page-default>
