import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';

import { AuthGuard } from './guards/auth.guard';
import { AuthChild } from './guards/authchild.guard';
import { ApprovedGuardChild } from './guards/approved.guard';
import { PageInitialComponent } from './home/page-initial/page-initial.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    children: [
      {
        path: 'home',
        component: PageInitialComponent,
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
      },
    ],
  },
  {
    path: 'agile-game',
    component: HomeComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    canActivateChild: [ApprovedGuardChild],
    children: [
      {
        path: 'team',
        loadChildren: () =>
          import('./team/team.module').then((m) => m.TeamModule),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
      {
        path: 'team-planning',
        loadChildren: () =>
          import('./team-planning/team-planning.module').then(
            (m) => m.TeamPlanningModule
          ),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
      {
        path: 'achieved-goals',
        loadChildren: () =>
          import('./achieved-goals/achieved-goals.module').then(
            (m) => m.AchievedGoalsModule
          ),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
      {
        path: 'mvt',
        loadChildren: () => import('./mvt/mvt.module').then((m) => m.MvtModule),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
      {
        path: 'user-log',
        loadChildren: () =>
          import('./user-log/user-log.module').then((m) => m.UserLogModule),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
    ],
  },
  {
    path: 'counting',
    component: HomeComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    children: [
      {
        path: 'collective',
        loadChildren: () =>
          import('./counting/counting.module').then((m) => m.CountingModule),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
      {
        path: 'individual',
        loadChildren: () =>
          import('./individual-calculation/individual-calculation.module').then(
            (m) => m.IndividualCalculationModule
          ),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
    ],
  },
  {
    path: 'history',
    component: HomeComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    children: [
      {
        path: 'my-goals',
        loadChildren: () =>
          import('./my-goals/my-goals.module').then((m) => m.MyGoalsModule),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
    ],
  },
  {
    path: 'matrix-competency',
    component: HomeComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    children: [
      {
        path: 'group-competences',
        loadChildren: () =>
          import('./group-competences/group-competences.module').then((m) => m.GroupCompetencesModule),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
      {
        path: 'competences',
        loadChildren: () =>
          import('./competency-matrix/competency-matrix.module').then((m) => m.CompetencyMatrixModule),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
      {
        path: 'config-matriz',
        loadChildren: () =>
          import('./matrix-competency-group/matrix-competency-config.module').then((m) => m.MatrixCompetencyConfigModule),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
      {
        path: 'matrix',
        loadChildren: () =>
          import('./matrix-team/matrix-team.module').then((m) => m.MatrixTeamModule),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
    ],
  },
  {
    path: 'dashboard-matrix',
    component: HomeComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    children: [
      {
        path: 'operational-vulnerability-dashboard',
        loadChildren: () =>
          import('./operational-vulnerability-dashboard/operational-vulnerability-dashboard.module').then((m) => m.OperationalVulnerabilityDashboardModule),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
      {
        path: 'plan-effectiveness-dashboard',
        loadChildren: () =>
          import('./plan-effectiveness-dashboard/plan-effectiveness-dashboard.module').then((m) => m.PlanEffectivenessDashboardModule),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
    ]
  },
  {
    path: 'adm',
    component: HomeComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    canActivateChild: [ApprovedGuardChild],
    children: [
      {
        path: 'users',
        loadChildren: () =>
          import('./users/users.module').then((m) => m.UsersModule),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
      {
        path: 'worker',
        loadChildren: () =>
          import('./worker/worker.module').then((m) => m.WorkerModule),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
      {
        path: 'tribes',
        loadChildren: () =>
          import('./tribes/tribes.module').then((m) => m.TribesModule),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
      {
        path: 'competence-type',
        loadChildren: () =>
          import('./competence-type/competence-type.module').then(
            (m) => m.CompetenceTypeModule
          ),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },

      {
        path: 'competence',
        loadChildren: () =>
          import('./competence/competence.module').then(
            (m) => m.CompetenceModule
          ),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
      {
        path: 'squads',
        loadChildren: () =>
          import('./squads/squads.module').then((m) => m.SquadsModule),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
      {
        path: 'approve',
        loadChildren: () =>
          import('./approve/approve.module').then((m) => m.ApproveModule),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
      {
        path: 'cicles',
        loadChildren: () =>
          import('./cicles/cicles.module').then((m) => m.CiclesModule),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
      {
        path: 'goals',
        loadChildren: () =>
          import('./goals/goals.module').then((m) => m.GoalsModule),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
      {
        path: 'goal-weight',
        loadChildren: () =>
          import('./goal-weight/goal-weight.module').then(
            (m) => m.GoalWeightModule
          ),
        canActivateChild: [AuthChild],
        canLoad: [AuthGuard],
      },
    ],
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
