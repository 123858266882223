<div class="po-wrapper">
  <po-toolbar p-title="Painel da Gestão" [p-profile-actions]="profileActions" p-profile-icon="po-icon-user">
  </po-toolbar>
  <po-menu p-collapsed [p-menus]="menus"></po-menu>
  <router-outlet></router-outlet>
</div>

<po-modal #registerUsers p-title="Primeiro Acesso" [p-primary-action]="save" [p-secondary-action]="close">
  <div class="po-row">
    <po-info p-label="Preencha com as informações de qual papel você exerce e equipe(s) que você pertence.">
    </po-info>
    <po-input class="po-lg-12" name="weight" [(ngModel)]="name" p-label="Nome Completo">
    </po-input>
    <po-select class="po-lg-12" name="selectedRoles" p-label="Papel" [(ngModel)]="selectedRoles"
      [p-options]="roles">
    </po-select>
    <po-multiselect class="po-lg-12" name="selectedSquads" p-label="Equipe" [(ngModel)]="selectedSquads"
      p-help="Ex. Segmento - Tribe - Equipe" [p-options]="squads">
    </po-multiselect>

    <po-select class="po-lg-12" name="selectedCareer" p-label="Nível de Carreira" [(ngModel)]="selectedCareer"
      [p-options]="career">
    </po-select>

  </div>
</po-modal>

<po-modal #notificationListModal p-title="Notificações">
  <po-list-view> </po-list-view>
</po-modal>