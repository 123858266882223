import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FormsModule } from '@angular/forms';

import { PoToolbarModule } from '@po-ui/ng-components';
import { PoMenuModule } from '@po-ui/ng-components';
import { PoPageModule } from '@po-ui/ng-components';
import { PoModalModule } from '@po-ui/ng-components';
import { PoListViewModule } from '@po-ui/ng-components';
import { PoStepperModule } from '@po-ui/ng-components';
import { PoButtonModule } from '@po-ui/ng-components';
import { PoFieldModule } from '@po-ui/ng-components';
import { PoInfoModule } from '@po-ui/ng-components';

import { HomeComponent } from './home.component';
import { PageInitialComponent } from './page-initial/page-initial.component';
@NgModule({
  declarations: [HomeComponent, PageInitialComponent],
  imports: [
    CommonModule,
    FormsModule,
    PoPageModule,
    PoToolbarModule,
    PoMenuModule,
    PoModalModule,
    PoListViewModule,
    PoStepperModule,
    PoButtonModule,
    PoFieldModule,
    PoInfoModule,
    RouterModule.forRoot([], {}),
  ],
  exports: [HomeComponent],
})
export class HomeModule {}
