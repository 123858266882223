
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root',
})
export class AuthChild  {
  constructor(private authcService: LoginService, private crouter: Router) {}

  canActivateChild(
    croute: ActivatedRouteSnapshot,
    cstate: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (this.authcService.checkToken()) {
      return true;
    } else {
      this.crouter.navigate(['login']);
      return false;
    }
  }
}
