import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PoStepperComponent, PoStepperItem, PoStepperStatus } from '@po-ui/ng-components';
import { lastValueFrom } from 'rxjs';
import { last } from 'rxjs/operators';
import { CiclesService } from 'src/app/cicles/cicles.service';
import { GoalWeightService } from 'src/app/goal-weight/goal-weight.service';
import { LoginService } from 'src/app/login/login.service';
import { LuxonAdapter } from 'src/app/shared/luxon-adapter';
import { UsersService } from 'src/app/users/users.service';
import { User } from 'src/classes/User';

@Component({
  selector: 'app-page-initial',
  templateUrl: './page-initial.component.html',
  styleUrls: ['./page-initial.component.scss'],
})
export class PageInitialComponent implements OnInit {
  @ViewChild(PoStepperComponent) poStepperComponent: PoStepperComponent;
  public nameUsr = '';
  public message = '';
  public labelButton = '';
  public showButton;
  public lastDay;
  public event: any;
  public year;
  public ciclesdisabled;
  public steppers = [];

  constructor(
    public loginservice: LoginService,
    public usersservice: UsersService,
    public ciclesService: CiclesService,
    private router: Router,
  ) {}

  steps: Array<PoStepperItem> = [];

  async ngOnInit(): Promise<void> {
    const idUsr = this.loginservice.userToken.id;
    const usr: User = await lastValueFrom(this.usersservice.getOne(idUsr));
    this.nameUsr = usr.name;
    if (usr.approved) {
      this.cycleStages()
      this.showButton = true;
    } else {
      this.message = 'Aguarde a aprovação do seu cadastro para ter acesso as funcionalidades do Painel da Gestão.'
      this.showButton = false;
    }

  }

  openAction() {
    const roles = this.loginservice.userToken.roles;
    if (roles.includes('teamlead')) {
      this.router.navigate(['agile-game/team/team-list']);
    } else {
      this.router.navigate(['/agile-game/achieved-goals']);
    }
  }
  

  async cycleStages() {
    let cicles = await lastValueFrom(this.ciclesService.getAgileGameActive())
    if (cicles.length > 1) {
      cicles = cicles.sort((a,b) => {return a.year < b.year? -1 : 1; })
    }
    if (cicles.length > 0) {
      let today = new Date().toISOString();

      let step2;

        cicles.forEach(cicle => {
        let nextDayResul = new Date(cicle.end);
        nextDayResul.setDate(nextDayResul.getDate() + 1);
        let registerStart = new Date(cicle.registerStart)
        let registerEnd = new Date(cicle.registerEnd)
        let start = this.formatteDate(registerStart.toISOString(), false);
        let end = this.formatteDate(registerEnd.toISOString(), false);
        let resultStart = this.formatteDate(nextDayResul.toISOString(), false);
        let resultEnd = this.formatteDate(nextDayResul.toISOString(), true);
  
        let step1 = this.compareDate(registerStart.toISOString(), registerEnd.toISOString(), today);
        let step3 = this.compareDate(nextDayResul.toISOString(), this.lastDay, today);

        if (step1 == 'completed' && step3 == '') {
          step2 = 'active'
        } else if (step3 == 'active' || step3 == 'completed') {
          step2 = 'completed'
        } else if (step1 == 'active') {
          step2 = '';
        }

        if (step3 !== 'completed') {
          this.steppers.push({
            name: cicle.name,
            goalResult: ` ${start} a ${end}`,
            result: `${resultStart} a ${resultEnd}`,
            step1 : this.compareDate(registerStart.toISOString(), registerEnd.toISOString(), today),
            step3: this.compareDate(nextDayResul.toISOString(), this.lastDay, today),
            step2: step2
          });
        }
      });
      this.ciclesdisabled = false;
    } else {
      this.ciclesdisabled = true;
    }
  }

  formatteDate(date, lastday: boolean) {
    let year = date.substring(0, 4);
    let month = date.substring(5, 7);
    let day = date.substring(8, 10);
    if (lastday) {
      this.lastDay = new Date(Number(year), Number(month - 1) + 1, 0).toISOString();
      year = this.lastDay.substring(0, 4);
      month = this.lastDay.substring(5, 7);
      day = this.lastDay.substring(8, 10);
    }
    let dateFormat = `${day}/${month}/${year}`
    return dateFormat;
  }

  compareDate(start, end, today) {
    let yearStart = start.substring(0, 4);
    let monthStart = start.substring(5, 7);
    let dayStart = start.substring(8, 10);

    let yearToday = today.substring(0, 4);
    let monthToday = today.substring(5, 7);
    let dayToday = today.substring(8, 10);

    let yearEnd = end.substring(0, 4);
    let monthEnd = end.substring(5, 7);
    let dayEnd = end.substring(8, 10);

    let startDate = new Date(yearStart, monthStart - 1, dayStart);
    let todayDate = new Date(yearToday, monthToday - 1, dayToday);
    let endDate = new Date(yearEnd, monthEnd - 1, dayEnd);

    let status;
    if (startDate.getTime() > todayDate.getTime()) {
      status = '';
    } else if (endDate.getTime() < todayDate.getTime()) {
      status = 'completed';
    } else if (startDate.getTime() <= todayDate.getTime() && endDate.getTime() >= todayDate.getTime()) {
      status = 'active';
    }

    return status;
  }
}
